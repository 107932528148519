import {Link, Typography} from "@mui/material";


export function Copyright() {
    return (
        <Typography variant={"body2"} align={"center"}>
            {'Copyright @'}
            <Link color={"inherit"} href={"https://cloudconnected.online"}>
                Cloud Connected
            </Link>
            {' '}{new Date().getFullYear()}
        </Typography>

    )

}
